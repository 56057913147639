import React from 'react';

import { t } from '@lingui/macro';
import { Link } from 'react-router-dom';

import { Chip, List, ListItem } from '@patternfly/react-core';
import CredentialChip from '../CredentialChip';
import ChipGroup from '../ChipGroup';
import Sparkline from '../Sparkline';
import { Detail, DeletedDetail } from '../DetailList';
import { VariablesDetail } from '../CodeEditor';
import ExecutionEnvironmentDetail from '../ExecutionEnvironmentDetail';
import { toTitleCase } from '../../util/strings';

function PromptJobTemplateDetail({ resource }) {
  const {
    allow_simultaneous,
    ask_inventory_on_launch,
    become_enabled,
    diff_mode,
    extra_vars,
    forks,
    host_config_key,
    instance_groups,
    job_slice_count,
    job_tags,
    job_type,
    limit,
    playbook,
    related,
    scm_branch,
    skip_tags,
    summary_fields,
    use_fact_cache,
    verbosity,
    webhook_key,
    webhook_service,
    custom_virtualenv,
  } = resource;

  const VERBOSITY = {
    0: t`0 (Normal)`,
    1: t`1 (Verbose)`,
    2: t`2 (More Verbose)`,
    3: t`3 (Debug)`,
    4: t`4 (Connection Debug)`,
  };

  let optionsList = '';
  if (
    become_enabled ||
    host_config_key ||
    allow_simultaneous ||
    use_fact_cache
  ) {
    optionsList = (
      <List>
        {become_enabled && (
          <ListItem>{t`Enable Privilege Escalation`}</ListItem>
        )}
        {host_config_key && (
          <ListItem>{t`Allow Provisioning Callbacks`}</ListItem>
        )}
        {allow_simultaneous && <ListItem>{t`Enable Concurrent Jobs`}</ListItem>}
        {use_fact_cache && <ListItem>{t`Use Fact Storage`}</ListItem>}
      </List>
    );
  }

  const inventoryKind =
    summary_fields?.inventory?.kind === 'smart'
      ? 'smart_inventory'
      : 'inventory';

  const recentJobs = summary_fields?.recent_jobs?.map(job => ({
    ...job,
    type: 'job',
  }));

  return (
    <>
      {summary_fields.recent_jobs?.length > 0 && (
        <Detail value={<Sparkline jobs={recentJobs} />} label={t`Activity`} />
      )}
      <Detail label={t`Job Type`} value={toTitleCase(job_type)} />
      {summary_fields?.organization ? (
        <Detail
          label={t`Organization`}
          value={
            <Link
              to={`/organizations/${summary_fields.organization.id}/details`}
            >
              {summary_fields?.organization.name}
            </Link>
          }
        />
      ) : (
        <DeletedDetail label={t`Organization`} />
      )}
      {summary_fields?.inventory ? (
        <Detail
          label={t`Inventory`}
          value={
            <Link
              to={`/${inventoryKind}/${summary_fields.inventory?.id}/details`}
            >
              {summary_fields.inventory?.name}
            </Link>
          }
        />
      ) : (
        !ask_inventory_on_launch && <DeletedDetail label={t`Inventory`} />
      )}
      {summary_fields?.project ? (
        <Detail
          label={t`Project`}
          value={
            <Link to={`/projects/${summary_fields.project?.id}/details`}>
              {summary_fields.project?.name}
            </Link>
          }
        />
      ) : (
        <DeletedDetail label={t`Project`} />
      )}
      <ExecutionEnvironmentDetail
        virtualEnvironment={custom_virtualenv}
        executionEnvironment={summary_fields?.execution_environment}
      />
      <Detail label={t`Source Control Branch`} value={scm_branch} />
      <Detail label={t`Playbook`} value={playbook} />
      <Detail label={t`Forks`} value={forks || '0'} />
      <Detail label={t`Limit`} value={limit} />
      <Detail label={t`Verbosity`} value={VERBOSITY[verbosity]} />
      {typeof diff_mode === 'boolean' && (
        <Detail label={t`Show Changes`} value={diff_mode ? t`On` : t`Off`} />
      )}
      <Detail label={t` Job Slicing`} value={job_slice_count} />
      <Detail label={t`Host Config Key`} value={host_config_key} />
      {related?.callback && (
        <Detail
          label={t`Provisioning Callback URL`}
          value={`${window.location.origin}${related.callback}`}
        />
      )}
      <Detail label={t`Webhook Service`} value={toTitleCase(webhook_service)} />
      {related?.webhook_receiver && (
        <Detail
          label={t`Webhook URL`}
          value={`${window.location.origin}${related.webhook_receiver}`}
        />
      )}
      <Detail label={t`Webhook Key`} value={webhook_key} />
      {summary_fields?.webhook_credential && (
        <Detail
          fullWidth
          label={t`Webhook Credential`}
          value={
            <CredentialChip
              key={summary_fields.webhook_credential?.id}
              credential={summary_fields.webhook_credential}
              isReadOnly
            />
          }
        />
      )}
      {optionsList && <Detail label={t`Options`} value={optionsList} />}
      {summary_fields?.credentials?.length > 0 && (
        <Detail
          fullWidth
          label={t`Credentials`}
          value={
            <ChipGroup
              numChips={5}
              totalChips={summary_fields.credentials.length}
            >
              {summary_fields.credentials.map(cred => (
                <CredentialChip key={cred.id} credential={cred} isReadOnly />
              ))}
            </ChipGroup>
          }
        />
      )}
      {summary_fields?.labels?.results?.length > 0 && (
        <Detail
          fullWidth
          label={t`Labels`}
          value={
            <ChipGroup
              numChips={5}
              totalChips={summary_fields.labels.results.length}
            >
              {summary_fields.labels.results.map(label => (
                <Chip key={label.id} isReadOnly>
                  {label.name}
                </Chip>
              ))}
            </ChipGroup>
          }
        />
      )}
      {instance_groups?.length > 0 && (
        <Detail
          fullWidth
          label={t`Instance Groups`}
          value={
            <ChipGroup numChips={5} totalChips={instance_groups.length}>
              {instance_groups.map(ig => (
                <Chip key={ig.id} isReadOnly>
                  {ig.name}
                </Chip>
              ))}
            </ChipGroup>
          }
        />
      )}
      {job_tags?.length > 0 && (
        <Detail
          fullWidth
          label={t`Job Tags`}
          value={
            <ChipGroup numChips={5} totalChips={job_tags.split(',').length}>
              {job_tags.split(',').map(jobTag => (
                <Chip key={jobTag} isReadOnly>
                  {jobTag}
                </Chip>
              ))}
            </ChipGroup>
          }
        />
      )}
      {skip_tags?.length > 0 && (
        <Detail
          fullWidth
          label={t`Skip Tags`}
          value={
            <ChipGroup numChips={5} totalChips={skip_tags.split(',').length}>
              {skip_tags.split(',').map(skipTag => (
                <Chip key={skipTag} isReadOnly>
                  {skipTag}
                </Chip>
              ))}
            </ChipGroup>
          }
        />
      )}
      {extra_vars && (
        <VariablesDetail label={t`Variables`} rows={4} value={extra_vars} />
      )}
    </>
  );
}

export default PromptJobTemplateDetail;
